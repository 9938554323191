import { Text } from "@clipboard-health/ui-react";
import { type DateRange } from "@src/appV2/lib/Calendar/types";
import type { ComponentProps } from "react";

import { DotSeparatedList } from "../components/DotSeparatedList";
import { formatDateRangeDuration } from "../utils/formatDateRangeDuration";
import { type ShiftFormattedPayBreakdown } from "./types";

interface WorkplaceInfoListProps {
  position?: string;
  workplaceName?: string;
  dateRange?: DateRange;
  payBreakdown?: ShiftFormattedPayBreakdown;
}

export const textDefaultProps: ComponentProps<typeof Text> = {
  semibold: true,
  color: (theme) => theme.palette.text.secondary,
  variant: "body2",
};

export function WorkplaceInfoList(props: WorkplaceInfoListProps) {
  const { position, workplaceName, dateRange, payBreakdown } = props;

  const listItems = [
    position,
    workplaceName,
    dateRange && formatDateRangeDuration(dateRange),
    payBreakdown && `${payBreakdown.hourlyPay} /hr`,
    payBreakdown && `${payBreakdown.totalPay} total`,
  ].filter(Boolean);

  return listItems.length > 0 ? (
    <Text {...textDefaultProps}>
      <DotSeparatedList>{listItems}</DotSeparatedList>
    </Text>
  ) : null;
}
