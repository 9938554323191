import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { getTimeSlotLabel } from "./getTimeSlotLabel";
import { type TimeSlot } from "./Open/types";
import { TimeSlotIcon } from "./TimeSlotIcon";

interface TimeSlotShiftsCountProps {
  timeSlot: TimeSlot;
  shiftsCount: number;
  isInactive?: boolean;
}

export function TimeSlotShiftsCount(props: TimeSlotShiftsCountProps) {
  const { timeSlot, shiftsCount, isInactive = false } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={(theme) => ({
        color: isInactive ? theme.palette.text.disabled : theme.palette.text.primary,
      })}
    >
      <TimeSlotIcon timeSlot={timeSlot} size="xSmall" />

      <Text
        semibold
        variant="caption"
        color={(theme) => (isInactive ? theme.palette.text.tertiary : theme.palette.text.primary)}
      >
        {shiftsCount} {getTimeSlotLabel(timeSlot).toLowerCase()}
      </Text>
    </Stack>
  );
}
