import { Text } from "@clipboard-health/ui-react";
import type { ComponentProps } from "react";

import { DotSeparatedList } from "../../components/DotSeparatedList";

interface BreakInfoListProps {
  skippedBreakTime?: string;
  skippedBreakTimePay?: string;
}

const textDefaultProps: ComponentProps<typeof Text> = {
  semibold: true,
  color: (theme) => theme.palette.text.secondary,
  variant: "body2",
};

export function BreakInfoList(props: BreakInfoListProps) {
  const { skippedBreakTime, skippedBreakTimePay } = props;

  const listItems = [
    skippedBreakTimePay && `${skippedBreakTimePay}`,
    skippedBreakTime && `${skippedBreakTime}`,
  ].filter(Boolean);

  return listItems.length > 0 ? (
    <Text {...textDefaultProps}>
      <DotSeparatedList>{listItems}</DotSeparatedList>
    </Text>
  ) : null;
}
