import { Box, CardContent, Skeleton, Stack, useTheme } from "@mui/material";

import { Card } from "../../components/Card";

export function HighlightedWorkerCardSkeleton() {
  const theme = useTheme();

  return (
    <Card
      outlined
      sx={{ flexGrow: 1, backgroundColor: theme.palette.background?.tertiary }}
      variant="tertiary"
      role="progressbar"
      aria-label="Loading shifts"
    >
      <CardContent>
        <Skeleton variant="rounded" width={80} height={theme.size?.pill.small} />
        <Stack direction="column" spacing={4} marginTop={12}>
          <Box>
            <Skeleton variant="text" height={theme.typography.h3.fontSize} />
            <Skeleton variant="text" height={theme.typography.h3.fontSize} width="60%" />
          </Box>
          <Skeleton variant="text" width="80%" />
          <Skeleton
            variant="rounded"
            height={theme.size?.button.medium.height}
            sx={{ borderRadius: theme.borderRadius?.large }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
