import { InternalLink } from "@clipboard-health/ui-react";
import { CardActionArea, CardContent, useTheme } from "@mui/material";

import { Card } from "../../components/Card";
import { IconButton } from "../../components/IconButton";
import {
  WorkerShiftSummaryInfoWithDate,
  type WorkerShiftSummaryInfoWithDateProps,
} from "./SummaryInfoWithDate";

export type WorkerShiftCardProps = {
  href: string;
} & Pick<
  WorkerShiftSummaryInfoWithDateProps,
  | "workplaceName"
  | "workplaceTimezone"
  | "shiftTimeSlot"
  | "shiftStart"
  | "shiftEnd"
  | "shiftAgentReq"
  | "shiftHourlyPay"
  | "shiftTotalPay"
>;

export function WorkerShiftCard(props: WorkerShiftCardProps) {
  const {
    workplaceName,
    workplaceTimezone,
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftAgentReq,
    shiftHourlyPay,
    shiftTotalPay,
    href,
  } = props;

  const theme = useTheme();

  return (
    <Card outlined variant="tertiary" sx={{ flexGrow: 1 }}>
      <CardActionArea component={InternalLink} to={href}>
        <CardContent sx={{ position: "relative", padding: theme.spacing(6) }}>
          <WorkerShiftSummaryInfoWithDate
            shiftTimeSlot={shiftTimeSlot}
            shiftStart={shiftStart}
            shiftEnd={shiftEnd}
            shiftAgentReq={shiftAgentReq}
            shiftHourlyPay={shiftHourlyPay}
            shiftTotalPay={shiftTotalPay}
            workplaceName={workplaceName}
            workplaceTimezone={workplaceTimezone}
          />
          <IconButton
            aria-label="Go to shift page"
            size="xSmall"
            variant="outlined"
            iconType="chevron-right"
            sx={{ position: "absolute", right: theme.spacing(6), top: theme.spacing(6) }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
