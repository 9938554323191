import { type TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib";
import { parseISO } from "date-fns";

import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { TimeSlotIndicator } from "../TimeSlotIndicator";
import { WorkplaceInfoList } from "../WorkplaceInfoList";
import { BreakInfoList } from "./BreakInfoList";

export interface WorkerShiftSummaryInfoWithDateProps {
  shiftTimeSlot: TimeSlot;
  shiftStart: string;
  shiftEnd: string;
  shiftAgentReq: string;
  shiftHourlyPay?: string;
  shiftTotalPay?: string;
  shiftSkippedBreakTime?: string;
  shiftSkippedBreakTimePay?: string;

  workplaceName: string;
  workplaceTimezone: string;
}

export function WorkerShiftSummaryInfoWithDate(props: WorkerShiftSummaryInfoWithDateProps) {
  const {
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftAgentReq,
    shiftHourlyPay,
    shiftTotalPay,
    shiftSkippedBreakTime,
    shiftSkippedBreakTimePay,
    workplaceName,
    workplaceTimezone,
  } = props;

  const dateRange: DateRange = { startDate: parseISO(shiftStart), endDate: parseISO(shiftEnd) };

  return (
    <Stack direction="row" spacing={5} alignItems="flex-start" flexGrow={1}>
      <TimeSlotIndicator timeSlot={shiftTimeSlot} />
      <Stack spacing={3} alignItems="flex-start" flexGrow={1}>
        <Stack spacing={2}>
          <Text semibold variant="body2">
            {formatDayOfMonthWithDayName(parseISO(shiftStart), { formatDayString: "EEEE" })}
          </Text>
          <TimeRangeLabel
            variant="h6"
            dateRange={dateRange}
            timezone={workplaceTimezone}
            deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
          />
        </Stack>

        <Stack spacing={1}>
          <WorkplaceInfoList position={shiftAgentReq} workplaceName={workplaceName} />
          <WorkplaceInfoList
            dateRange={dateRange}
            payBreakdown={
              isDefined(shiftHourlyPay) && isDefined(shiftTotalPay)
                ? { hourlyPay: shiftHourlyPay, totalPay: shiftTotalPay }
                : undefined
            }
          />
          <BreakInfoList
            skippedBreakTime={shiftSkippedBreakTime}
            skippedBreakTimePay={shiftSkippedBreakTimePay}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
