import { mergeSxProps } from "@clipboard-health/ui-react";
import { useGetQualifications } from "@src/appV2/Qualifications/api/useGetQualifications";

import { type BasePillProps, Pill } from "../components/Pill";
import { PillSkeleton } from "../components/PillSkeleton";

type ShiftQualificationPillProps = Omit<BasePillProps, "iconType" | "label"> & {
  qualificationName: string;
};

export function ShiftQualificationPill(props: ShiftQualificationPillProps) {
  const { qualificationName, variant = "outlined", sx, ...pillProps } = props;

  const {
    data: qualificationsData,
    isSuccess: qualificationsIsSuccess,
    isLoading: qualificationsIsLoading,
  } = useGetQualifications({ enabled: true });

  if (qualificationsIsLoading) {
    return <PillSkeleton width="2rem" />;
  }

  if (!qualificationsIsSuccess) {
    return null;
  }

  const qualification = qualificationsData?.data.find(
    (q) => q.attributes.name === qualificationName
  );

  return (
    <Pill
      size="medium"
      label={qualification?.attributes.displayLabel ?? qualificationName}
      variant={variant}
      sx={mergeSxProps({ width: "fit-content" }, sx)}
      {...pillProps}
    />
  );
}
