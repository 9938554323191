import { Pill } from "../../components/Pill";

export function BookedShiftPill() {
  return (
    <Pill
      size="medium"
      iconType="checkmark"
      label="Booked"
      backgroundColor={(theme) => theme.palette.intent?.success.background}
      color={(theme) => theme.palette.intent?.success.text}
    />
  );
}
