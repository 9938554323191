import { Pill } from "../../components/Pill";

interface UrgentShiftPillProps {
  /**
   * If true, the pill will be compact and only show the icon
   */
  compact?: boolean;
}

export function UrgentShiftPill(props: UrgentShiftPillProps) {
  const { compact = false } = props;

  return (
    <Pill
      size="medium"
      label={compact ? undefined : "Urgent"}
      iconType="time-flies"
      backgroundColor={(theme) => theme.palette.shift?.urgent.background}
      color={(theme) => theme.palette.shift?.urgent.text}
    />
  );
}
