import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Stack, type Theme, useTheme } from "@mui/material";
import { isBefore, isSameMinute, parseISO, startOfMinute } from "date-fns";

import { Button } from "../../components/Button";
import { Pill } from "../../components/Pill";
import { formatDateRangeDuration } from "../../utils/formatDateRangeDuration";
import { formatDateRangeDurationCompact } from "../../utils/formatDateRangeDurationCompact";
import { formatTime } from "../../utils/formatTime";
import { CurrentShiftStage } from "../constants";
import { type TimeSlot } from "../Open/types";
import { WorkplaceInfoList } from "../WorkplaceInfoList";
import { HighlightedWorkerShiftCardWrapper } from "./HighlightedCardWrapper";

export interface HighlightedWorkerShiftCardProps {
  href: string;
  workplaceName: string;
  workplaceTimezone: string;

  shiftStart: string;
  shiftEnd: string;
  shiftPosition: string;
  shiftHourlyPay?: string;
  shiftTotalPay?: string;
  shiftStage: CurrentShiftStage;
  shiftTimeSlot: TimeSlot;

  onClick?: () => void;
}

function getShiftStatusParameters(props: {
  workplaceName: string;
  workplaceTimezone: string;
  shiftStage: CurrentShiftStage;
  shiftPosition: string;
  startDate: Date;
  endDate: Date;
  theme: Theme;
}) {
  const { workplaceName, workplaceTimezone, shiftStage, shiftPosition, startDate, endDate, theme } =
    props;

  let titleText = "";
  let pillText = "";
  let pillColor = theme.palette.background.tertiary;

  const startsIn = formatDateRangeDuration({ startDate, endDate: new Date() });
  const shiftDescription = `your ${shiftPosition} shift at ${formatTime(
    startDate,
    workplaceTimezone
  )} at ${workplaceName}`;

  switch (shiftStage) {
    case CurrentShiftStage.NOT_STARTED: {
      const now = startOfMinute(new Date());
      const isShiftStarted = isBefore(startDate, now) || isSameMinute(startDate, now);

      if (isShiftStarted) {
        pillText = "Shift started";
        titleText = `Clock in to ${shiftDescription}`;
      } else {
        pillText = `Starts in ${startsIn}`;
        titleText = `Prepare for ${shiftDescription}`;
      }

      break;
    }

    case CurrentShiftStage.CLOCKED_IN:
    case CurrentShiftStage.BREAK_STARTED:
    case CurrentShiftStage.BREAK_ENDED: {
      const endsIn = formatDateRangeDurationCompact({ startDate: new Date(), endDate });
      pillText = "In progress";
      pillColor = theme.palette.intent?.success.background;
      titleText = `Your ${shiftPosition} shift is in progress with ${endsIn} remaining.`;

      break;
    }

    case CurrentShiftStage.CLOCKED_OUT: {
      pillText = "Clocked out";
      titleText = `Submit your timesheet for ${shiftDescription}`;

      break;
    }

    case CurrentShiftStage.PENDING_VERIFICATION: {
      pillText = "Clocked out";
      titleText = `Timesheet is pending verification for ${shiftDescription}`;
      break;
    }

    case CurrentShiftStage.VERIFIED: {
      pillText = "Verified";
      titleText = `Timesheet has been verified for ${shiftDescription}`;
      break;
    }

    default:
  }

  return { titleText, pillText, pillColor };
}

export function HighlightedWorkerShiftCard(props: HighlightedWorkerShiftCardProps) {
  const {
    href,
    workplaceName,
    workplaceTimezone,
    shiftStart,
    shiftEnd,
    shiftPosition,
    shiftHourlyPay,
    shiftTotalPay,
    shiftStage,
    shiftTimeSlot,
    onClick,
  } = props;
  const theme = useTheme();

  const startDate = parseISO(shiftStart);
  const endDate = parseISO(shiftEnd);

  const { titleText, pillText, pillColor } = getShiftStatusParameters({
    shiftStage,
    shiftPosition,
    startDate,
    endDate,
    workplaceName,
    workplaceTimezone,
    theme,
  });

  return (
    <HighlightedWorkerShiftCardWrapper shiftTimeSlot={shiftTimeSlot}>
      <CardContent>
        <Pill size="medium" label={pillText} backgroundColor={() => pillColor} />
        <Stack direction="column" spacing={4} marginTop={12}>
          <Text variant="h5">{titleText}</Text>
          <WorkplaceInfoList
            workplaceName={workplaceName}
            payBreakdown={
              isDefined(shiftHourlyPay) && isDefined(shiftTotalPay)
                ? {
                    hourlyPay: shiftHourlyPay,
                    totalPay: shiftTotalPay,
                  }
                : undefined
            }
          />
          <Button role="button" variant="contained" href={href} onClick={onClick}>
            View details{shiftStage === CurrentShiftStage.NOT_STARTED ? " and clock in" : ""}
          </Button>
        </Stack>
      </CardContent>
    </HighlightedWorkerShiftCardWrapper>
  );
}
