import { Stack } from "@mui/material";
import { type DateRange } from "@src/appV2/lib";
import { parseISO } from "date-fns";

import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { getFormattedShiftPayBreakdown } from "../getFormattedShiftPayBreakdown";
import { TimeSlotIcon } from "../TimeSlotIcon";
import { type WorkerShiftWithType } from "../types";
import { WorkplaceInfoList } from "../WorkplaceInfoList";

export interface WorkerShiftSummaryInfoProps {
  shift: Pick<
    WorkerShiftWithType,
    "facility" | "name" | "start" | "end" | "agentReq" | "offer" | "finalPay" | "originalAmount"
  >;
}

export function WorkerShiftSummaryInfo(props: WorkerShiftSummaryInfoProps) {
  const { shift } = props;
  const { facility, name: timeSlot, start, end, agentReq, offer, finalPay, originalAmount } = shift;

  const payBreakdown = getFormattedShiftPayBreakdown(offer?.pay.value, finalPay, originalAmount);

  const dateRange: DateRange = { startDate: parseISO(start), endDate: parseISO(end) };

  return (
    <Stack spacing={3} direction="row" alignItems="flex-start">
      <TimeSlotIcon timeSlot={timeSlot} size="medium" />

      <Stack spacing={1}>
        <TimeRangeLabel
          semibold
          dateRange={dateRange}
          timezone={facility.tmz}
          deviceTimezone={new Intl.DateTimeFormat().resolvedOptions().timeZone}
          variant="h5"
        />

        <WorkplaceInfoList
          position={agentReq}
          workplaceName={facility.name}
          dateRange={dateRange}
        />

        <WorkplaceInfoList payBreakdown={payBreakdown} />
      </Stack>
    </Stack>
  );
}
