import { isDefined } from "@clipboard-health/util-ts";
import { type Shift } from "@src/appV2/Shifts/Shift/types";

import { CurrentShiftStage } from "../constants";

export function getShiftCurrentStage(shift: Shift): CurrentShiftStage {
  if (shift.verified) {
    return CurrentShiftStage.VERIFIED;
  }

  const timecardFiles = shift.timecard?.files;
  if (isDefined(timecardFiles) && timecardFiles.length > 0) {
    return CurrentShiftStage.PENDING_VERIFICATION;
  }

  const hasClockOut = isDefined(shift.clockInOut?.end);
  if (hasClockOut) {
    return CurrentShiftStage.CLOCKED_OUT;
  }

  const hasBreakEnd = isDefined(shift.lunchInOut?.end);
  if (hasBreakEnd) {
    return CurrentShiftStage.BREAK_ENDED;
  }

  const hasBreakStart = isDefined(shift.lunchInOut?.start);
  if (hasBreakStart) {
    return CurrentShiftStage.BREAK_STARTED;
  }

  const hasClockIn = isDefined(shift.clockInOut?.start);
  if (hasClockIn) {
    return CurrentShiftStage.CLOCKED_IN;
  }

  return CurrentShiftStage.NOT_STARTED;
}
